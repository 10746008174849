import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {Flexed, Spacer, Text} from '../styled/shared'
import {palette} from '../styled/colors'
import {DropContent, DropMenu, Icons, MenuList, ProfileImg} from '../components/TopNavBar'
import Button from '../components/common/Button'
import Input from '../components/common/input/Input'
import {Dot} from './SignUp'
import useRouter from '../hooks/router'
import moment from 'moment'
import MultiSelect from '../components/common/MultiSelect'
import {useTranslation} from 'react-i18next'
import {Effects} from './effects'
import {useSelector} from 'react-redux'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {api} from '../helpers/auth-axios'
import {chatAllUsers, chatUnreadMessages, chatUserDeleteMessages, chatUserDeleteMessagesBottom, chatUserMessages, chatUserMessagesBottom, chatUsers, networkUsers, saveUser} from '../actions/authActions'
import {useDispatch} from 'react-redux'
import InviteFriends from '../components/InviteFriends'
import {FaTrash} from 'react-icons/fa'
import {BiBlock, BiTrash} from 'react-icons/bi'
import ConfirmationPopup from '../components/modals/ConfirmationModel'
import {toast} from 'react-toastify'
import Loader from '../components/common/Loader'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import EmojiPicker from 'emoji-picker-react'
import UserProfileLoader from '../components/common/UserProfileLoader'
import Footer from '../components/Footer'

const Inbox = () => {
	const router: any = useRouter()
	const userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const myNetworkUsers: any = useSelector<any>((state: any) => state.auth.networkUser)
	const [openEmoji, setOpenEmoji] = useState(false)
	const [newMessage, setNewMessage] = useState(false)
	const [selectedUser, setSelectedUser] = useState<any>('')
	const [selectedUserIndex, setSelectedUserIndex] = useState<any>('')
	const menuRef: any = useRef(null)
	const dispatch = useDispatch()
	const [handleComment, setHandleComment] = useState<any>('')
	const [loggedInUser, setLoggedInUser] = useState<CometChat.User | null | undefined>()
	const [isMobileView, setIsMobileView] = useState(false)
	const [fileInputVisible, setFileInputVisible] = useState(false)
	const [selectedNewChatUsers, setSelectedNewChatUsers] = useState<any[]>([])
	const {t} = useTranslation()
	let _chatUsers: any = useSelector<any>((state: any) => state.auth.chatUsers)
	const _chatAllUsers: any = useSelector<any>((state: any) => state.auth.chatAllUsers)
	const userMessage: any = useSelector<any>((state: any) => state.auth.userMessage)
	const chatRef: any = useRef()
	const [conversationMessages, setConversationMessages] = useState<any[]>([])
	const [messageLaoding, setMessageLoading] = useState(false)
	const [openMenu, setOpenMenu] = useState(false)
	const _menuRef: any = useRef(null)
	const [openMenuIndex, setOpenMenuIndex] = useState(null)
	const [confirmationModel, setConfirmationModel] = useState({type: '', open: false})
	const [userId, setUserId] = useState<any>('')
	const [chatFetchedUsers, setChatUsers] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [networkUser, setNetworkUsers] = useState<any>([])
	const emojiPickerRef: any = useRef(null)
	const [submitting, setSubmitting] = useState(false)
	const [messagePage, setMessagePage] = useState(1)
	const [page, setPage] = useState(1)
	const [newUsers, setNewUsers] = useState<any>([])
	const [messageLastId, setMessagesLastId] = useState<any>(1)
	const deleteUserMessage: any = useSelector<any>((state: any) => state.auth.deleteUserMessage)

	const newMessageUsers: any[] = newUsers?.map((user: any) => ({
		label: (
			<Flexed direction={'row'} align={'center'} gap={'0.5'}>
				<ProfileImg height={'2.8rem'} width={'2.8rem'}>
					<Icons src={user?.metadata.image_Url ? user?.metadata?.image_Url : user?.avatar ? user?.avatar : `/images/user_default.png`} alt="userDefault" />
				</ProfileImg>
				<Text fontSize={'0.9'} lineHeight={'1.12'} fontWeight={'500'} color={'dark_blue'}>
					{user?.name}
				</Text>
				{user?.metadata?.linkedin_profile_verified || user?.metadata?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
			</Flexed>
		),
		value: user
	}))
	useEffect(() => {
		let usersss: any = _chatUsers?.length ? _chatAllUsers.filter((user: any) => !_chatUsers.some((e: any) => e?.conversationWith?.uid === user?.uid)) : _chatAllUsers
		usersss = usersss?.filter((e: any) => (networkUser?.length ? networkUser?.includes(e?.uid) : myNetworkUsers?.includes(e?.uid)))
		setNewUsers(usersss)
	}, [networkUser?.length, _chatUsers?.length, _chatAllUsers?.length])

	useEffect(() => {
		setNetworkUsers(myNetworkUsers)
		getNetworkUsers(newMessage ? false : true)
	}, [userDetails?.chat_id])

	useEffect(() => {
		if (deleteUserMessage?.id) {
			let messages = conversationMessages.filter((e: any) => e.id != deleteUserMessage?.id)
			setConversationMessages(messages)
			dispatch(chatUserDeleteMessages({}))
		}
	}, [deleteUserMessage?.id])

	useEffect(() => {
		if (newMessage) getNetworkUsers(newMessage ? false : true)
	}, [userDetails?.chat_id, newMessage])

	useEffect(() => {
		const updateMessage = async (id: any) => {
			try {
				let res = await api.post(`/user/update_chat_message`, {id})
				router.replace('/inbox')
			} catch (error) {}
		}
		if (router.query?.id && router.query?.utm_source == 'sendgrid.com' && chatFetchedUsers?.length) {
			let data = chatFetchedUsers.find((e: any) => e?.conversationWith?.uid == router.query?.id)
			if (data?.lastMessage?.id) {
				router.replace('/inbox')
				updateMessage(data?.lastMessage?.id)
			}
		}
	}, [router.query, conversationMessages, chatFetchedUsers])

	useEffect(() => {
		if (userMessage?.id) {
			if (selectedUser?.uid == userMessage?.receiverId) {
				setConversationMessages((prevMessages: any) => [...prevMessages, userMessage])
				let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == userMessage?.receiverId || e?.conversationWith?.uid == userMessage?.receiver?.uid ? {...e, unreadMessageCount: 0, lastMessage: userMessage} : e))
				setChatUsers(userss)
				dispatch(chatUsers(userss))

				scrollToBottom()
			} else {
				let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == userMessage?.sender?.uid || e?.conversationWith?.uid == userMessage?.receiver?.uid ? {...e, unreadMessageCount: 0, lastMessage: userMessage} : e))
				setChatUsers(userss)
				dispatch(chatUsers(userss))
			}
			setTimeout(() => {
				dispatch(chatUserMessages({}))
			}, 3000)
		}
	}, [userMessage?.id])

	useEffect(() => {
		setChatUsers(_chatUsers)

		if (router?.query?.id && !selectedUser) {
			let user = _chatUsers?.find((e: any) => e?.conversationWith?.uid == router?.query?.id)
			let allUser = newMessageUsers?.find((e: any) => (e?.value?.uid).toLowerCase() == (router?.query?.id).toLowerCase())

			setSelectedUser(user ? user?.conversationWith : allUser?.value)
		}
	}, [router?.query?.id, _chatUsers])

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (_menuRef.current && !_menuRef.current.contains(event.target)) {
				setOpenMenu(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [_menuRef])

	const getNetworkUsers = async (loading = true) => {
		setLoading(router?.query?.id ? false : loading)
		await api
			.get('/user/network_users')
			.then((res: any) => {
				if (res?.data?.status) {
					if (res?.data?.data) {
						setNetworkUsers(res?.data?.data)
						dispatch(networkUsers(res?.data?.data))
					}
				}
				setLoading(false)
			})
			.catch((error: any) => {
				setLoading(false)
				console.log('====error', error)
			})
	}

	const groupedMessages = (chatMessages?: any) => {
		return chatMessages?.reduce((grouped: any, message: any) => {
			// Extract date from message createdAt
			const date = moment.unix(message.sentAt).format('YYYY-MM-DD')

			// Add message to the corresponding date array
			if (grouped[date]) {
				grouped[date].push(message)
			} else {
				grouped[date] = [message]
			}

			return grouped
		}, {})
	}

	const handleImageIconClick = () => {
		// Show the file input for image selection
		setFileInputVisible(true)
	}

	const handleAttachmentIconClick = () => {
		// Show the file input for file attachment
		setFileInputVisible(true)
	}
	let ids: any = newMessage && selectedNewChatUsers?.length ? selectedNewChatUsers?.map((e: any) => e?.value?.uid) : []

	const handleFileInputChange = async (event: any) => {
		const file = event.target.files[0]
		if (ids?.length) {
			setLoading(true)
			let messageAdded: any[] = []
			for (let i = 0; i < ids.length; i++) {
				let receiverID: string = ids[i],
					messageType: string = file?.type?.startsWith('image') ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE,
					receiverType: string = CometChat.RECEIVER_TYPE.USER,
					mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType)

				await CometChat.sendMediaMessage(mediaMessage).then(
					async (message: any) => {
						messageAdded.push(message)
						scrollToBottom()
						if (message?.receiver?.metadata?.email) {
							await firstMessageEmail(
								message?.receiver?.metadata?.email,
								message?.receiver?.metadata?.full_name ? message?.receiver?.metadata?.full_name : message?.receiver?.metdata?.first_name,
								message?.sender?.uid,
								message?.receiver?.metadata
							)
						}
						dispatch(chatUserMessagesBottom(message))
					},
					(error: CometChat.CometChatException) => {
						console.log('Media message sending failed with error', error)
					}
				)

				setFileInputVisible(false)
				if (i == ids.length - 1 && messageAdded?.length) {
					getUsers()
				}
			}
			setLoading(false)
			setSubmitting(false)
			setSelectedNewChatUsers([])
			setNewMessage(false)
		} else {
			let receiverID: string = selectedUser?.uid,
				messageType: string = file?.type?.startsWith('image') ? CometChat.MESSAGE_TYPE.IMAGE : CometChat.MESSAGE_TYPE.FILE,
				receiverType: string = CometChat.RECEIVER_TYPE.USER,
				mediaMessage: CometChat.MediaMessage = new CometChat.MediaMessage(receiverID, file, messageType, receiverType)

			await CometChat.sendMediaMessage(mediaMessage).then(
				(message: any) => {
					setConversationMessages((prevMessages: any) => [...prevMessages, message])
					scrollToBottom()
					dispatch(chatUserMessagesBottom(message))
				},
				(error: CometChat.CometChatException) => {
					console.log('Media message sending failed with error', error)
				}
			)
			setFileInputVisible(false)
		}
	}

	Effects({
		setLoggedInUser,
		setIsMobileView
	})

	useEffect(() => {
		if (page > 1) {
			getUsers(30 * page, page, false)
		} else {
			getUsers(30 * page, page, true)
		}
	}, [page, userDetails?.chat_id, networkUser?.length])

	useEffect(() => {
		if (selectedUser?.uid) {
			getUserConversation(selectedUser?.uid, 1, messageLastId)
		}
	}, [selectedUser])

	useEffect(() => {
		if (conversationMessages?.length == messagePage * 50 && messageLastId > 1) {
			getUserConversation(selectedUser?.uid, messagePage + 1, messageLastId)
		}
	}, [conversationMessages?.length, messagePage, messageLastId])

	const getUserConversation = async (uid?: any, page = 1, lastMessageId = 1) => {
		try {
			console.log('=====lastMessageId', lastMessageId, chatFetchedUsers[selectedUserIndex || 0])
			if (uid) {
				setMessageLoading(true)

				let messagesRequest = new CometChat.MessagesRequestBuilder().setUID(uid).setMessageId(lastMessageId).hideDeletedMessages(true).setLimit(50).build()
				await messagesRequest.fetchNext().then(
					async (messages: any[]) => {
						if (messages?.length) {
							if (page > 1) {
								setConversationMessages([...conversationMessages, ...messages])
							} else {
								setConversationMessages(messages)
							}

							let lastIndexId = messages[messages.length - 1]?.id ?? 1
							setMessagesLastId(lastIndexId)

							setMessagePage(page)
							if (chatFetchedUsers[selectedUserIndex || 0]) {
								let messageid = chatFetchedUsers[selectedUserIndex || 0]
								if (messageid?.lastMessage?.id) {
									let res = await CometChat.markAsRead(messageid?.lastMessage)
								}
							}

							let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == uid ? {...e, unreadMessageCount: 0} : e))
							CometChat.getUnreadMessageCount().then((res: any) => {
								dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
								return res
							})

							setChatUsers(data)
							dispatch(chatUserMessages({}))
							dispatch(chatUsers(data))
						}

						scrollToBottom()
						setMessageLoading(false)
					},
					(error) => {
						setMessageLoading(false)
						console.log('inbox-Conversations list fetching failed with error:', error)
					}
				)
			}
		} catch (error) {
			console.log('Initialization failed with error:', error)
		}
	}

	const getUsers = async (limit: number = 30, page: number = 1, loading = true) => {
		try {
			setLoading(loading)
			const result: any = await CometChat.getUnreadMessageCount()
			dispatch(chatUnreadMessages(Object.keys(result?.users)?.length))
			if (userDetails?.chat_id) {
				if (page == 1) {
					await CometChatUIKit.login(userDetails?.chat_id)?.then((loggedInUser) => {
						console.log('loggedInUser app', loggedInUser)
					})
				}

				const usersRequest = new CometChat.UsersRequestBuilder()
					.hideBlockedUsers(true)
					.setUIDs(networkUser?.length ? networkUser : myNetworkUsers)
					.setLimit(limit)
					.build()

				let conversationsRequest = new CometChat.ConversationsRequestBuilder().setLimit(50).withUserAndGroupTags(true).build()

				conversationsRequest.fetchNext().then(
					(conversationList) => {
						let conversation = conversationList.filter((res: any) => !res?.conversationWith?.deactivatedAt)
						dispatch(chatUsers(conversation))
						setLoading(false)
						if (conversationList.length === limit) {
							setPage(page + 1)
						}
					},
					(error) => {
						setLoading(false)
						console.log('Conversations list fetching failed with error:', error)
					}
				)
				try {
					const userList = await usersRequest.fetchNext()
					dispatch(chatAllUsers(userList))
					if (userList.length === limit) {
						setPage(page + 1)
						// await getUsers(limit, page + 1, false)
					}
					setLoading(false)
				} catch (error) {
					setLoading(false)
					console.error('Error fetching users:', error)
				}
			}
		} catch (error) {
			setLoading(false)
			console.error('Error fetching users:', error)
		}
	}

	const scrollToBottom = () => {
		if (chatRef?.current) {
			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}
	}
	useEffect(() => {
		setTimeout(() => {
			scrollToBottom()
		}, 100)
	}, [conversationMessages])

	const firstMessageEmail = async (email: any, name: any, chatId: any, userDetails: any) => {
		try {
			await api.post('/user/first_message_email', {email, name, chatId, userDetails}).then((res: any) => {
				console.log('======message email sent', res?.data)
			})
		} catch (error) {}
	}
	const sendMessage = async () => {
		try {
			setSubmitting(true)
			if (ids?.length) {
				let messageAdded: any[] = []
				for (let i = 0; i < ids.length; i++) {
					let receiverID: any = ids[i],
						messageText: string = handleComment,
						receiverType: string = CometChat.RECEIVER_TYPE.USER,
						textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType)

					await CometChat.sendMessage(textMessage).then(
						async (message: any) => {
							if (message?.receiver?.metadata?.email) {
								await firstMessageEmail(
									message?.receiver?.metadata?.email,
									message?.receiver?.metadata?.full_name ? message?.receiver?.metadata?.full_name : message?.receiver?.metdata?.first_name,
									message?.sender?.uid,
									message?.receiver?.metadata
								)
							}
							messageAdded.push(message)
							setHandleComment('')
							dispatch(chatUserMessagesBottom(message))
						},
						(error: CometChat.CometChatException) => {
							setSubmitting(false)
							console.log('Message sending failed with error:', error)
						}
					)

					if (i == ids.length - 1 && messageAdded?.length) {
						getUsers()
						setNewMessage(!newMessage)
					}
				}
				setSubmitting(false)

				setNewMessage(!newMessage)
				setSelectedNewChatUsers([])
			} else {
				let receiverID: any = selectedUser?.uid,
					messageText: string = handleComment,
					receiverType: string = CometChat.RECEIVER_TYPE.USER,
					textMessage: CometChat.TextMessage = new CometChat.TextMessage(receiverID, messageText, receiverType)

				CometChat.sendMessage(textMessage).then(
					(message: any) => {
						setHandleComment('')
						dispatch(chatUserMessagesBottom(message))
						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == selectedUser?.uid ? {...e, lastMessage: message} : e))
						setChatUsers(userss)

						setConversationMessages((prevMessages: any) => [...prevMessages, message])
						scrollToBottom()
						if (router?.query?.id) {
							getUsers()
							router.replace('/inbox')
						}
						setSubmitting(false)
					},
					(error: CometChat.CometChatException) => {
						setSubmitting(false)
						console.log('Message sending failed with error:', error)
					}
				)
			}
		} catch (error) {
			setSubmitting(false)
		}
	}

	const MessageComponent = (message: any) => {
		if (message.hasOwnProperty('url')) {
			// Check for download URL
			const filename = message?.attachments.length ? message?.attachments[0].name : ''
			const downloadUrl = message.url

			return (
				<div className="pdf-message">
					<a href={downloadUrl} target="_blank" download={filename}>
						{filename} (Download)
					</a>
				</div>
			)
		}

		return null
	}

	useEffect(() => {
		CometChat.addMessageListener(
			'CHAT_COMPONENT_MESSAGE_LISTENER',
			new CometChat.MessageListener({
				onTextMessageReceived: (textMessage: any) => {
					if (ids?.length) {
						getUsers()
					}
					if (!_chatUsers?.some((e: any) => e?.conversationWith?.uid == textMessage?.sender?.uid)) {
						getUsers()
					}

					if (selectedUser?.uid == textMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, textMessage])
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 0} : e))
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 0, lastMessage: textMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))

						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 0} : e))
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})
						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == textMessage?.sender?.uid || e?.conversationWith?.uid == textMessage?.receiver?.uid ? {...e, unreadMessageCount: 1, lastMessage: textMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMediaMessageReceived: (mediaMessage: any) => {
					if (ids.length) {
						getUsers()
					}
					if (selectedUser?.uid == mediaMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, mediaMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						// dispatch(chatUnreadMessages(totalUnread?.length + 1))

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == mediaMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: mediaMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onCustomMessageReceived: (customMessage: any) => {
					if (ids?.length) {
						getUsers()
					}

					if (selectedUser?.uid == customMessage?.sender?.uid) {
						setConversationMessages((prevMessages: any) => [...prevMessages, customMessage])
						scrollToBottom()
					} else {
						let data = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 0} : e))

						let totalUnread = data.filter((e: any) => e.unreadMessageCount)
						CometChat.getUnreadMessageCount().then((res: any) => {
							dispatch(chatUnreadMessages(Object.keys(res?.users)?.length))
							return res
						})

						let userss = chatFetchedUsers.map((e: any) => (e?.conversationWith?.uid == customMessage?.sender?.uid ? {...e, unreadMessageCount: 1, lastMessage: customMessage} : e))
						setChatUsers(userss)
						dispatch(chatUsers(userss))
					}
				},
				onMessagesDelivered: (messageReceipt: any) => {
					// console.log('==========messageReceipt', messageReceipt)
					if (ids.length) {
						getUsers()
					}
					// if (selectedUser?.uid == messageReceipt?.sender?.uid) {
					// 	setConversationMessages((prevMessages: any) => [...prevMessages, messageReceipt])
					// 	scrollToBottom()
					// }
				},
				onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {},
				onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
					// console.log('==========typingIndicator', typingIndicator)
				},
				onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
					// console.log('==========typingIndicator', typingIndicator)
				},
				onMessageDeleted: (message: any) => {
					// console.log('==========message delete', message)
					if (ids.length) {
						getUsers()
					}
					if (!_chatUsers?.some((e: any) => e?.conversationWith?.uid == message?.sender?.uid)) {
						getUsers()
					}
					if (selectedUser?.uid == message?.sender?.uid) {
						if (selectedUser?.uid == message?.sender?.uid) {
							let messages = conversationMessages.filter((e: any) => e.id != message?.id)
							setConversationMessages(messages)
							scrollToBottom()
						}
					}
				},
				onMessagesEdited: (message: CometChat.BaseMessage) => {},
				onInteractiveMessageReceived: (message: any) => {
					console.log('interactive Message received', message)
				},
				onInteractionGoalCompleted: (message: any) => {
					console.log('Message interaction goal completed', message)
				}
			})
		)
	})

	const blockUser = async (uid?: any) => {
		let usersList: String[] = [uid]

		CometChat.blockUsers(usersList).then(
			(list: any) => {
				getUsers()
				setConfirmationModel({open: false, type: ''})
				toast.success(list[uid]?.message)
			},
			(error: CometChat.CometChatException) => {
				console.log('Blocking user fails with error', error)
			}
		)
	}

	const deleteUserChat = async (uid?: any) => {
		try {
			let UID: string = uid
			let type: string = 'user'
			CometChat.deleteConversation(UID, type).then(
				(deletedConversation: string) => {
					if (uid == selectedUser?.uid) {
						setConversationMessages([])
						setSelectedUser('')
					}

					setConfirmationModel({open: false, type: ''})
					getUsers()
				},
				(error: CometChat.CometChatException) => {
					console.log('error while deleting a conversation', error)
				}
			)
		} catch (error) {}
	}

	const deleteMessage = async (messageId?: any) => {
		try {
			await CometChat.deleteMessage(messageId).then(
				async (message: CometChat.BaseMessage) => {
					let messages = conversationMessages.filter((e: any) => e.id != messageId)
					let _message = conversationMessages.find((e: any) => e.id == messageId)
					dispatch(chatUserDeleteMessagesBottom(_message))
					setConversationMessages(messages)

					// await getUserConversation(selectedUser?.uid, 1, 1)
				},
				(error: CometChat.CometChatException) => {
					console.log('Message delete failed with error:', error)
				}
			)
		} catch (error) {}
	}

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenEmoji(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<MainContainer>
			<Spacer height={5} />
			<Container fluid>
				{loading && <Loader />}
				{/* <Chat /> */}
				<Row>
					<Col lg={9}>
						<Card background={palette.white} padding={'0rem'} overflow={'visible'}>
							<Row>
								<Col lg={4}>
									<Flexed padding={'1.6rem'} style={{borderRight: `1px solid rgba(0, 0, 0, 0.12)`}}>
										<Spacer height={0.2} />
										{newUsers?.length ? (
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												padding={'0 1rem'}
												label={
													<>
														{' '}
														{t('NEW MESSAGE')} &nbsp; <Icons src={'/images/icon_new message_white.svg'} />
													</>
												}
												background={palette.blue_500}
												color={palette.white}
												lineHeight={'0.91rem'}
												ifClicked={() => {
													setSelectedNewChatUsers([newMessageUsers[0]])
													setNewMessage(!newMessage)
													setSelectedUser('')
												}}
											/>
										) : (
											''
										)}
										<Spacer height={1.5} />
										<Text fontSize={'1'} lineHeight={'1.21'} fontWeight={'700'} color={'text_color'} align={'left'}>
											{t('Direct Messages')}
										</Text>
										<Spacer height={1} />
										<Flexed height={'44.3rem'} style={{overflow: 'hidden', overflowY: 'scroll'}}>
											{chatFetchedUsers?.length ? (
												chatFetchedUsers?.map((user: any, i: any) => {
													return (
														!(user?.conversationWith?.hasBlockedMe || user?.conversationWith?.blockedByMe) && (
															<NetwrokUsers
																direction="column"
																align={'center'}
																padding={'10px 12px 10px 12px'}
																background={selectedUser?.uid == user?.conversationWith?.uid ? palette.active_inbox_user : ''}
																radius={'0.8rem'}
																// pointer
															>
																<CustomFlexedForWrap direction="column" width={'100%'} align={'left'} justify={'space-between'}>
																	<Flexed
																		direction="row"
																		justify={'space-between'}
																		align={user?.lastMessage?.text?.length >= 70 ? 'start' : 'center'}
																		pointer
																		onClick={() => {
																			setHandleComment('')
																			setSelectedUserIndex(i)
																			setNewMessage(false)
																			setMessagesLastId(1)
																			setSelectedUser(user?.conversationWith)
																		}}>
																		<Flexed direction="row" align={user?.lastMessage?.text?.length >= 70 ? 'start' : 'center'}>
																			<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
																				<UserProfileLoader
																					height={'2.8rem'}
																					width={'2.8rem'}
																					imgUrl={
																						user?.conversationWith?.avatar
																							? user?.conversationWith?.avatar
																							: user?.conversationWith?.metadata?.image_Url
																							? user?.conversationWith?.metadata?.image_Url
																							: `/images/user_default.png`
																					}
																				/>
																			</ProfileImgWrapper>

																			<Flexed direction="column" justify={'space-between'} align={'start'} padding={'0.5rem '}>
																				<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'}>
																					<Text fontSize={'1'} lineHeight={'1.2'} fontWeight={'600'} color={'black'} opacity={'87%'}>
																						{user?.conversationWith?.name}
																					</Text>
																					{user?.conversationWith?.metadata?.linkedin_profile_verified || user?.conversationWith?.metadata?.linkedin_id ? <Icons src={`/images/linkedin.svg`} /> : ''}
																				</Flexed>
																				<Spacer height={0.2} />
																				<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} isCentered>
																					{user?.conversationWith?.designation}
																				</Text>
																				<Flexed direction="row" justify={'center'} align={'center'} gap={'0.3'} flexWrap={'wrap'} overflow={'hidden'}>
																					{user?.lastMessage?.text ? (
																						<Text
																							fontSize={'0.8'}
																							lineHeight={'1'}
																							fontWeight={user?.unreadMessageCount ? '600' : '400'}
																							color={'black'}
																							opacity={user?.unreadMessageCount ? '1' : '57%'}
																							wordBreak>
																							{userDetails?.chat_id != user?.lastMessage?.receiverId ? t('You:') + user?.lastMessage?.text : t('Replied:') + user?.lastMessage?.text}
																						</Text>
																					) : (
																						''
																					)}
																				</Flexed>
																			</Flexed>
																		</Flexed>
																		<MenuList type="normal" onSmall>
																			<Text
																				onClick={(e: any) => {
																					e?.stopPropagation()
																					setOpenMenu(true)
																					if (openMenuIndex === i) {
																						setOpenMenuIndex(null)
																					} else {
																						setOpenMenuIndex(i)
																					}
																				}}>
																				<Icons pointer={'pointer'} src={'/images/icon_options.svg'} />
																			</Text>
																			{openMenuIndex == i ? (
																				<DropContent ref={_menuRef} top={'92%'} display={openMenu ? true : false}>
																					<DropDownList
																						padding={'0.5rem'}
																						direction={'row'}
																						gap="0.3"
																						style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}
																						onClick={(e: any) => {
																							e?.stopPropagation()
																							setUserId(user?.conversationWith?.uid)
																							setConfirmationModel({type: 'block', open: true})
																						}}>
																						<BiBlock /> {t('Block User')}
																					</DropDownList>
																					<DropDownList
																						padding={'0.5rem'}
																						direction={'row'}
																						gap="0.3"
																						style={{display: 'flex', alignItems: 'center', gap: '0.2rem'}}
																						onClick={(e: any) => {
																							e?.stopPropagation()
																							setUserId(user?.conversationWith?.uid)
																							setConfirmationModel({type: 'delete', open: true})
																						}}>
																						<FaTrash /> {t('Delete Conversation')}
																					</DropDownList>
																				</DropContent>
																			) : (
																				''
																			)}
																		</MenuList>
																	</Flexed>
																</CustomFlexedForWrap>
																<Spacer height={0.2} />
															</NetwrokUsers>
														)
													)
												})
											) : (
												<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'} margin={'auto'}>
													<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
														{t('No user available')}
													</Text>
												</Flexed>
											)}
										</Flexed>
									</Flexed>
								</Col>
								<Col lg={8}>
									<CustomFlexedForMessages padding={'2rem 2rem 1rem 0rem '}>
										{newMessage ? (
											<Text fontSize={'1.5'} lineHeight={'1.843'} fontWeight={'600'} color={'text_color'} align={'left'}>
												{t('New Message')}
											</Text>
										) : selectedUser ? (
											<Text fontSize={'1.5'} lineHeight={'1.843'} fontWeight={'600'} color={'text_color'} align={'left'}>
												{t('Chat with')} {selectedUser?.name}
											</Text>
										) : (
											''
										)}
										{newMessage ? (
											<MultiSelect
												hideSelectedOptions={true}
												isSearchable={false}
												isChat={true}
												isMulti={true}
												value={selectedNewChatUsers}
												noOptionsMessage={'No user found'}
												options={newMessageUsers}
												onChange={(e: any) => setSelectedNewChatUsers(e)}
											/>
										) : (
											''
										)}
										<Spacer height={0.5} />
										<div>
											{selectedUser?.uid ? (
												<Card background={'transparent'} borderColor={'#0000001F'} display={!selectedUser?.uid && 'flex'}>
													{selectedUser ? (
														messageLaoding ? (
															<Flexed direction={'row'} justify={'center'} align={'center'} height={'100%'} style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
																<Loader isComponent={true} />
															</Flexed>
														) : (
															<Flexed height={'20.64rem'} style={{overflow: 'hidden', overflowY: 'scroll'}} gap={'1'} ref={chatRef}>
																{newMessage
																	? ''
																	: Object.entries(groupedMessages(conversationMessages?.filter((e: any) => e?.action != 'deleted'))).map(([date, messages]: any) => (
																			<Flexed key={date} direction="column" padding={'10px 12px'}>
																				<Flexed direction={'row'} align={'center'} justify={'center'} width={'auto'}>
																					<Flexed background={palette.primary_50} direction={'row'} padding={'10px 16px 10px 16px'} radius={'19px'} align={'center'} justify={'center'} width={'auto'}>
																						<Text fontSize={'0.7'} lineHeight={'0.82'} fontWeight={'400'} color={'black'} isCentered>
																							{moment(date).format('dddd, MMMM Do')}
																						</Text>
																					</Flexed>
																				</Flexed>
																				<Spacer height={1} />
																				{messages.map((message: any, index: any) => (
																					<Flexed
																						key={index + 'a'}
																						direction="row"
																						align="center"
																						justify={message?.receiver?.uid == selectedUser?.uid ? 'flex-end' : 'flex-start'}
																						padding={'10px 0'}
																						radius={'0.8rem'}
																						pointer>
																						<Spacer height={0.5} />
																						<Flexed direction="row" align="start" gap={0.5}>
																							{message?.sender?.uid == selectedUser?.uid ? (
																								<ProfileImgWrapper
																									justify="center"
																									direction={'row'}
																									align={'center'}
																									gap={'0.2'}
																									onClick={() => {
																										message?.sender?.metadata?.id &&
																											router.navigate(`/${message?.sender?.metadata?.user_name}`, {
																												state: {route: `/inbox?id=${selectedUser?.uid}`}
																											})
																									}}>
																									<UserProfileLoader height={'2.8rem'} width={'2.8rem'} imgUrl={message?.sender?.avatar ? message?.sender?.avatar : `/images/user_default.png`} />
																								</ProfileImgWrapper>
																							) : (
																								''
																							)}

																							<CustomMessageFlex direction="row" gap={'0.2'}>
																								<Flexed direction="column">
																									<Flexed
																										direction="column"
																										align="start"
																										width={'auto'}
																										padding={message?.receiver?.uid == selectedUser?.uid ? '10px 15px 10px 20px' : '10px 15px 10px 20px'}
																										radius={message?.receiver?.uid == selectedUser?.uid ? '20px 20px 20px 0px' : '20px 20px 0px 20px'}
																										onHover={true}
																										background={
																											message?.data?.type == 'file' || message?.data?.url ? '' : message?.receiver?.uid == selectedUser?.uid ? palette.reply_bg : palette.blue_500
																										}>
																										<Text fontSize={'0.9'} lineHeight={'1.05'} fontWeight={'400'} color={message?.receiver?.uid == selectedUser?.uid ? 'black' : 'white'} wordBreak>
																											{message?.data?.type == 'file' || (message?.data?.attachments?.length && message?.data?.attachments[0]?.extension == 'pdf') ? (
																												MessageComponent(message?.data)
																											) : message?.data?.url && !message?.data?.url?.endsWith('.pdf') ? (
																												<Flexed direction={'row'}>
																													<img width={'100%'} src={message?.data?.url} />
																												</Flexed>
																											) : (
																												message.text
																											)}
																										</Text>
																									</Flexed>
																									<Spacer height={0.2} />

																									<Text fontSize={'0.8'} lineHeight={'0.92'} fontWeight={'400'} color={'black'} opacity={'60%'} align={message.type === 'reply' ? 'left' : 'right'}>
																										{moment.unix(message.sentAt).format('hh:mm A')}
																									</Text>
																								</Flexed>

																								<Flexed>
																									<TrashMesssageIcons>
																										{message?.receiver?.uid == selectedUser?.uid && <BiTrash onClick={() => message?.id && deleteMessage(message?.id)} />}
																									</TrashMesssageIcons>
																								</Flexed>
																							</CustomMessageFlex>
																						</Flexed>
																					</Flexed>
																				))}
																			</Flexed>
																	  ))}
															</Flexed>
														)
													) : (
														<Flexed justify={'center'} align={'center'} direction={'row'} height={'100%'} margin={'auto'}>
															<Text fontSize={'0.8'} lineHeight={'1'} fontWeight={'400'} color={'text_color'} isCentered>
																{t('Select a user to start messaging')}
															</Text>
														</Flexed>
													)}
												</Card>
											) : (
												''
											)}
										</div>
									</CustomFlexedForMessages>
									{newMessage && selectedNewChatUsers?.length && openEmoji ? <Spacer height={12} /> : ''}
									{selectedUser || (newMessage && selectedNewChatUsers?.length) ? (
										<form
											onSubmit={(e) => {
												e.preventDefault()
												if (selectedUser || selectedNewChatUsers?.length) {
													handleComment?.trim()?.length && sendMessage()
												}
											}}>
											<CustomFlexedForMessages padding={'0.5rem 2rem 1rem 0rem '} gap={'1'}>
												<Input label={t('Message')} isSignup isContact height={'70.4px'} value={handleComment} onChange={(e: any) => setHandleComment(e?.value)} />

												<Flexed direction={'row'} align={'center'} justify={'space-between'}>
													<Flexed direction={'row'} align={'center'} gap={'0.5'}>
														<label htmlFor="imageInput" style={{marginBottom: '0rem'}}>
															<Icons src={'/images/icon_image.svg'} pointer={'pointer'} onClick={handleImageIconClick} />
														</label>
														<label htmlFor="attachmentInput" style={{marginBottom: '0rem'}}>
															<Icons src={'/images/icon_link.svg'} pointer={'pointer'} onClick={handleAttachmentIconClick} />
														</label>
														{/* <Flexed>
															<Icons ref={menuRef} src={'/images/icon_smile.svg'} pointer={'pointer'} onClick={() => setOpenEmoji(!openEmoji)} />
														</Flexed> */}
														{/* <div
															ref={emojiPickerRef}
															style={
																{
																	// position: 'absolute',
																	// top: '50%',
																	// left: '16%',
																	// display: openEmoji ? 'block' : 'none'
																}
															}>
															<Picker
																style={{
																	position: 'absolute',
																	top: '39%',
																	left: '16%',
																	zIndex: 999,
																	height: '100%',
																	display: openEmoji ? 'block' : 'none',
																	overflow: 'scroll'
																}}
																open={openEmoji}
																onEmojiClick={(e: any) => {
																	// alert(e?.emoji)
																	if (e?.emoji) {
																		setHandleComment(handleComment + e?.emoji)
																	}
																}}
															/>
														</div> */}

														<EmojiPickerWrapper>
															<EmojiButton
																onClick={(e) => {
																	setOpenEmoji(!openEmoji)
																}}
																ref={menuRef}>
																<Icons src={'/images/icon_smile.svg'} alt="i" pointer />
															</EmojiButton>
															{openEmoji && (
																<PickerContainer ref={emojiPickerRef}>
																	<EmojiPicker
																		onEmojiClick={(e) => {
																			setHandleComment(handleComment + e?.emoji)
																		}}
																	/>
																</PickerContainer>
															)}
														</EmojiPickerWrapper>

														<input id="imageInput" type="file" accept="image/*" style={{display: 'none'}} onChange={handleFileInputChange} />

														<input id="attachmentInput" type="file" style={{display: 'none'}} onChange={handleFileInputChange} />
													</Flexed>
													<Button
														small
														borderRadius={'1rem'}
														fontSize={'0.8rem'}
														fontWeight={600}
														padding={'0 1rem'}
														label={<>{t('SEND')}</>}
														background={palette.blue_500}
														color={palette.white}
														lineHeight={'0.91rem'}
														disabled={submitting}
														// ifClicked={() => handleComment?.trim()?.length && sendMessage()}
													/>
												</Flexed>
											</CustomFlexedForMessages>
										</form>
									) : (
										''
									)}
								</Col>
							</Row>
						</Card>
					</Col>
					<Col lg={3}>
						<Row>
							<Col lg={12}>
								<CustomSmallSpace height={2} />

								<Card background={palette.default_theme}>
									<ProBadgeWrapper>
										<ProBadgeCircle></ProBadgeCircle>
										<Icons height={'90%'} width={'90%'} src={'/images/pro_badge.svg'} />
									</ProBadgeWrapper>
									<Spacer height={2} />

									<Text fontSize={'0.9'} lineHeight={'1.02'} fontWeight={'500'} color={'white'} isCentered>
										{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? t('You are already a') : t('Become a')}
									</Text>
									<Spacer height={0.5} />
									<Text fontSize={'1.25'} lineHeight={'1.51'} fontWeight={'600'} color={'white'} isCentered>
										{t('Pro Member')}
									</Text>
									<Spacer height={1} />
									<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
										{t('Setup your own BMT Values')}
									</Text>

									<Spacer height={0.5} />
									<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
										<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
											{t('Exclusive Access')}
										</Text>
										<Dot bg={palette.secondary_900} />
										<Text fontSize={'0.9'} lineHeight={'1.08'} fontWeight={'400'} color={'secondary_900'} isCentered fontStyle={'italic'}>
											{t('Premium Visibility')}
										</Text>
									</Flexed>
									<Spacer height={1.5} />
									<Flexed direction="row" justify={'center'} align={'center'} gap={'0.4'}>
										{userDetails?.proMembership && userDetails?.proMembership.length && !userDetails.proMembership[0].is_deleted ? (
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												icon={'icon.png'}
												padding={'0 1rem'}
												label={<>{t('BUY COINS')}</>}
												background={palette.white}
												color={palette.text_color}
												lineHeight={'0.91rem'}
												ifClicked={() => router.navigate('/buy-coins')}
											/>
										) : (
											<Button
												small
												borderRadius={'1rem'}
												fontSize={'0.8rem'}
												fontWeight={600}
												icon={'icon.png'}
												padding={'0 1rem'}
												label={<>{t('UPGRADE NOW')}</>}
												background={palette.white}
												color={palette.text_color}
												lineHeight={'0.91rem'}
												ifClicked={() => router.navigate('/upgrade-bmt-membership')}
											/>
										)}
									</Flexed>
									<Spacer height={0.5} />
								</Card>
							</Col>

							<Col lg={12}>
								<Spacer height={0.5} />
								<InviteFriends />
							</Col>
						</Row>
					</Col>
				</Row>
				<Footer />
				{/* <Spacer height={4} /> */}
			</Container>

			{confirmationModel.open && (
				<ConfirmationPopup
					header={confirmationModel.type == 'delete' ? t('Are you sure you want to delete this user chat?') : t('Are you sure you want to block this user?')}
					onClose={() => {
						setUserId('')
						setConfirmationModel({open: false, type: ''})
					}}
					buttonTitle={confirmationModel.type == 'delete' ? t('Delete') : t('Block')}
					onConfirm={() => {
						if (userId) {
							confirmationModel.type == 'delete' ? deleteUserChat(userId) : blockUser(userId)
						}
					}}
				/>
			)}
		</MainContainer>
	)
}

const customDecorator = (href: any, text: any, key: any) => (
	<a href={href} key={key} target="_blank" rel="noopener noreferrer">
		{text}
	</a>
)

const MainContainer = styled.div`
	background-image: url('/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%; /* Set height to 100vh for full viewport height */
	padding: 1rem;
	padding-bottom: 0;
`

const Card = styled.div<any>`
	position: relative;
	overflow: ${({overflow}) => (overflow ? overflow : `hidden`)};
	text-align: center;
	padding: ${({padding}) => (padding ? padding : ` 0.5rem`)};
	border-radius: ${({radius}) => (radius ? radius : ` 1.25rem`)};
	min-height: ${({minHeight}) => (minHeight ? minHeight : ` 13rem`)};
	width: ${({width}) => (width ? width : `100%`)};
	display: ${({display}) => display};
	opacity: ${({opacity}) => opacity};
	cursor: default;
	box-shadow: ${({removeShadow}) => (removeShadow ? `none` : `0px 0px 20px 0px #0000000d`)};
	border: ${({borderColor}) => (borderColor ? `1px solid ` + borderColor : `none`)};
	height: ${({height}) => (height ? height : `100%`)};
	/* margin-bottom: 1.875rem; */
	background: ${({background}) => (background ? background : palette.blue_500)};

	${media.xs`
	 min-height:auto;
	 /* width:auto; */
	 padding: ${({padding}: any) => (padding ? '0.5rem' : ` 0.5rem`)};
	 `};
`

const CustomFlexed = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	flex-direction: column;
	 `};
`

const ProBadgeWrapper = styled(Flexed)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	> img {
		position: absolute;
		height: 70%;
	}
`

const ProBadgeCircle = styled(Flexed)`
	background: white;
	opacity: 5%;
	width: 94px;
	height: 94px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const CustomSmallSpace = styled(Spacer)`
	display: none;
	${media.xs`
display: block
	 `};
`

const CustomFlexedForWrap = styled(Flexed)`
	flex-wrap: nowrap;
	${media.xs`
	flex-wrap: wrap;
	 `};
`

export const CustomFlexedForMessages = styled(Flexed)`
	padding: ${({padding}) => padding};
	${media.xs`
	padding: 1rem ;
	 `};
`

export const TrashMesssageIcons = styled.div`
	display: none;
`
export const CustomMessageFlex = styled(Flexed)<any>`
	&:hover ${TrashMesssageIcons} {
		display: block;
	}
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

export const NetwrokUsers = styled(Flexed)`
	:hover {
		background: ${palette.active_inbox_user};
	}
`

export const DropDownList = styled(Flexed)`
	:hover {
		background: ${palette.active_inbox_user};
	}
`

const EmojiPickerWrapper = styled.div`
	position: relative;
`

const EmojiButton = styled.div`
	background: transparent;
	border: none;
	cursor: pointer;
`

const PickerContainer = styled.div`
	position: absolute;
	bottom: 5rem;
	right: 0;
	z-index: 9999;
	${media.xs`
	left: -5rem;
bottom: 6rem;
	 `};
	${media.sm`
	left: -5rem;
bottom: 6rem;
	 `};
	${media.md`
	left:50%;
bottom: 6rem;
	 `};
	${media.lg`
bottom: 6rem;
	 `};
`
export default Inbox

import React, {useRef, useState, useEffect, useMemo} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import Button from './common/Button'
import {palette} from '../styled/colors'
import {Flexed, Spacer, Text} from '../styled/shared'
import styled from 'styled-components'
import {Col, Row, media} from 'styled-bootstrap-grid'
import {toast} from 'react-toastify'
import useRouter from '../hooks/router'
import {Icons, ProfileImg} from './TopNavBar'
import {Card} from '../pages/Dashboard'
import InputField from './common/InputField'
import InputPhoneNo from './common/InputPhoneNo'
import {Country, State, City} from 'country-state-city'
import MultiSelect from './common/MultiSelect'
import {useSelector} from 'react-redux'
import {api} from '../helpers/auth-axios'
import Loader from './common/Loader'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {callAutoLogin, saveUser} from '../actions/authActions'
import moment from 'moment'
import VerifyPhoneModal from './modals/VerifyPhoneModal'
import {CometChat} from '@cometchat/chat-sdk-javascript'
import {CometChatUIKit} from '@cometchat/chat-uikit-react'
import {AppConstants} from '../AppConstants'

const UpdateProfileForm = ({closeForm, editProfile}: {closeForm?: any; editProfile?: any}) => {
	const router = useRouter()
	const inputFileRef: any = useRef(null)
	const [profileImage, setProfileImage] = useState<any>('/images/user_default.png')
	const optionsForCountry = useMemo(() => Country.getAllCountries(), [])
	const [countryOption, setCountryOption] = useState([])
	const [cityOption, setCityOption] = useState([])
	const _userDetails: any = useSelector<any>((state: any) => state.auth.userDetails)
	const [loading, setLoading] = useState(false)
	const [optVerificationModel, setOtpVerificationModel] = useState(false)
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const [selectedTimezone, setSelectedTimezone] = useState<any>('')
	const [userPhone, setUserPhone] = useState<any>('')

	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

	const genderOptions = [
		{value: 'male', label: t('Male')},
		{value: 'female', label: t('Female')},
		{value: 'other', label: t('Other')}
	]

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required(t('First Name is required')),
		last_name: Yup.string().required('Last Name is required'),
		email: Yup.string().email('Invalid email').required('Email is required'),
		phone: Yup.string().min(8).required('Phone is required'),
		linkedin_url: Yup.string().matches(/^(https?:\/\/)/, t('LinkedIn URL must start with http:// or https://')),
		website_url: Yup.string().matches(/^(https?:\/\/)/, t('Website URL must start with http:// or https://'))
	})

	let initialValues: any = {
		first_name: _userDetails?.first_name,
		last_name: _userDetails?.last_name,
		email: _userDetails?.email,
		phone: _userDetails?.phone,
		designation: _userDetails?.designation,
		organization: _userDetails?.organization,
		country: _userDetails?.country,
		city: _userDetails?.city,
		linkedin_url: _userDetails?.linkedin_url,
		website_url: _userDetails?.website_url,
		user_name: _userDetails?.user_name,
		specifications: _userDetails?.specifications,
		gender: _userDetails?.gender ? genderOptions.find((e: any) => e.value == _userDetails?.gender) : ''
	}

	useEffect(() => {
		const _countries: any = []
		for (let i = 0; i < optionsForCountry.length; i++) {
			_countries.push({value: optionsForCountry[i].isoCode, label: optionsForCountry[i].name})
		}
		setCountryOption(_countries)

		if (initialValues?.city) {
			findCities(_countries?.find((re: any) => re?.label == initialValues.country)?.value)
		}
	}, [optionsForCountry])

	useEffect(() => {
		if (_userDetails) {
			setProfileImage(_userDetails?.image_Url ? _userDetails?.image_Url : '/images/user_default.png')
			setUserPhone(_userDetails?.phone)
		}
	}, [_userDetails])

	// Function to handle file upload
	const handleFileUpload = (e: any) => {
		const file = e.target.files[0] // Get the uploaded file
		if (file) {
			const reader: any = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => {
				if (reader.readyState === 2) {
					setProfileImage(reader.result)
				}
			}
		}
	}

	const handleSubmit = async (values: any, actions: any) => {
		try {
			setLoading(true)
			await api
				.post('/user/update', {...values, new_profile_image: profileImage, country: values?.country?.label ?? '', city: values?.city?.label ?? '', gender: values?.gender?.value})
				.then((res: any) => {
					if (res?.data?.status) {
						actions.setSubmitting(false)
						actions.resetForm()
						updateUserInChat(res?.data?.data)
						dispatch(callAutoLogin(true))
						toast.success(res?.data?.message)
						closeForm && closeForm(true)
						setLoading(false)
					} else {
						setLoading(false)
						toast.error(res?.data?.message)
					}
				})
				.catch((error: any) => {
					setLoading(false)
					if (error?.message) {
						toast.error(error?.message)
					}
				})
		} catch (error) {
			setLoading(false)
			console.log('======error', error)
		}
	}

	const updateUserInChat = async (user: any) => {
		try {
			const newUser = new CometChat.User(user?.chat_id)
			if (newUser) {
				newUser.setName(user?.full_name)
				newUser.setMetadata(user)
				newUser.setAvatar(user?.image_Url)
				try {
					let _user = await CometChatUIKit.getLoggedinUser()
						?.then((loggedInUser) => {
							return loggedInUser
						})
						.catch((err) => {
							console.log('=====error at login', err)
							return null
						})

					if (_user) {
						await CometChat.updateCurrentUserDetails(newUser).then(
							(user) => {
								console.log('updated user', user)
							},
							(error) => {
								console.log('error', error)
							}
						)
					}
				} catch (error) {
					console.log('error', error)
				}
			}
		} catch (error) {}
	}

	const findCities = (selectedCountry: string) => {
		setCityOption([])
		let cities: any = City.getCitiesOfCountry(selectedCountry)
		cities = cities?.map((e: any) => ({label: e.name, value: e.name}))
		setCityOption(cities)
	}

	const sendOtp = async () => {
		if (!userPhone) {
			toast.warn(t('Please enter phone first to verify'))
			return
		}
		setLoading(true)
		await api
			.post('/user/send_phone_otp', {phone: userPhone ? userPhone : initialValues?.phone, email: _userDetails?.email})
			.then((res: any) => {
				if (res?.data?.status) {
					setLoading(false)
					setOtpVerificationModel(true)
					toast.success(res?.data?.message)
				} else {
					setLoading(false)
					toast.error(res?.data?.message)
				}
			})
			.catch((error: any) => {
				setLoading(false)
				if (error?.message) {
					toast.error(error?.message)
				}
			})
	}

	return (
		<Row justifyContent="center">
			{loading && <Loader />}
			<Col xl={12} lg={12} md={12} sm={12} xs={12}>
				<Spacer height={0} />
				<Card background={palette.white} padding={'0'}>
					<Flexed justify="center">
						<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
							{({isSubmitting, errors, setFieldValue, values, touched}) => (
								<Form>
									<Row>
										<Col xl={6} lg={6} md={6} sm={12} xs={12} className="column-with-border" style={{padding: '2rem 2.3rem 1rem 3.2rem'}}>
											<Flexed justify="start" direction={'row'} align={'center'} gap={'0.2'}>
												<Icons width={'3%'} height={'3%'} src={'/images/icon_view.svg'} style={{cursor: 'pointer'}} onClick={() => closeForm && closeForm()} />
												<Text fontSize={'1.2'} lineHeight={'1.27'} fontWeight={'700'} color={'text_color'}>
													{editProfile ? t('Edit profile') : 'Create profile'}
												</Text>
											</Flexed>
											<Spacer height={2} />
											<ProfileImgWrapper justify="center" direction={'row'} align={'center'} gap={'0.2'}>
												<ProfileImg width={'144px'} height={'144px'}>
													<Icons src={profileImage ? profileImage : '/images/user_default.png'} alt="userDefault" onClick={() => inputFileRef?.current && inputFileRef.current.click()} />
													<input type="file" accept="image/*" ref={inputFileRef} style={{display: 'none'}} onChange={handleFileUpload} />
												</ProfileImg>
												<UploadIconWrapper src={'/images/icon_upload.svg'} alt="upload" onClick={() => inputFileRef?.current && inputFileRef.current.click()} />
											</ProfileImgWrapper>
											<Spacer height={2} />
											<NameFieldsFlex direction={'row'} justify={'space-between'} gap={'1.2'}>
												<Flexed direction={'column'} width={'100%'}>
													<Field
														component={InputField}
														name="first_name"
														type="text"
														label={t('First Name')}
														value={values.first_name ?? null}
														isSignup
														isContact
														onChange={(event: any) => {
															setFieldValue('first_name', event?.value, true)
														}}
														error={touched.first_name && errors.first_name ? {message: errors.first_name ?? ''} : null}
													/>
												</Flexed>
												<Flexed direction={'column'} width={'100%'}>
													<Field
														component={InputField}
														name="last_name"
														type="text"
														label={t('Last Name')}
														isSignup
														isContact
														value={values.last_name ?? undefined}
														onChange={(event: any) => {
															setFieldValue('last_name', event?.value, true)
														}}
														error={touched.last_name && errors.last_name ? {message: errors.last_name ?? ''} : null}
													/>
												</Flexed>
											</NameFieldsFlex>
											<Spacer height="1" />
											<Field
												component={InputField}
												name="email"
												type="email"
												label={t('Email')}
												isEmailVerified
												value={values.email ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('email', event?.value, true)
												}}
												error={touched.email && errors.email ? {message: errors.email ?? ''} : null}
											/>

											<Spacer height="1" />
											<Field
												component={InputPhoneNo}
												name="phone"
												label={t('Enter your phone')}
												value={values.phone ?? undefined}
												isSignup
												// verifyPhone={() => sendOtp()}
												isContact
												// isPhoneVerified={_userDetails?.is_phone_verified}
												onChange={(event: any) => {
													setUserPhone(event?.value)
													setFieldValue('phone', event?.value, true)
												}}
												error={touched.phone && errors.phone ? errors.phone : null}
											/>
											<Spacer height="1" />
											<Field
												component={InputField}
												name="designation"
												type="designation"
												label={t('designation')}
												placeholder={t('Enter your designation')}
												value={values.designation ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('designation', event?.value, true)
												}}
												error={touched.designation && errors.designation ? {message: errors.designation ?? ''} : null}
											/>

											<Spacer height="1" />
											<Field
												component={InputField}
												name="organization"
												type="company"
												label={t('company')}
												placeholder={t('Enter your company')}
												value={values.organization ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('organization', event?.value, true)
												}}
												error={touched.organization && errors.organization ? {message: errors.organization ?? ''} : null}
											/>
											<Spacer height="1" />
										</Col>

										<Col xl={6} lg={6} md={6} sm={12} xs={12} className="column-with-border" style={{padding: '2rem 3.2rem 1rem 2.3rem'}}>
											<Flexed>
												<Field
													component={MultiSelect}
													name="country"
													type="country"
													label={t('COUNTRY')}
													placeholder={t('Choose country')}
													value={values.country ? countryOption?.find((re: any) => re?.label == values.country) : undefined}
													isSignup
													isContact
													options={countryOption}
													onChange={(event: any) => {
														setFieldValue('country', event, true)
														findCities(event?.value)
														setFieldValue('city', '', true)
													}}
													error={touched.country && errors.country ? {message: errors.country ?? ''} : null}
												/>
											</Flexed>

											<Spacer height={1.5} />
											<Field
												component={MultiSelect}
												name="city"
												type="text"
												label={t('CITY')}
												options={cityOption}
												placeholder={t('Choose city')}
												value={values.city ? cityOption?.find((re: any) => re?.label == values.city) : undefined}
												isSignup
												isContact
												noOptionsMessage={values.country ? '' : t('Please Select Country First')}
												onChange={(event: any) => {
													setFieldValue('city', event, true)
												}}
												error={touched.city && errors.city ? {message: errors.city ?? ''} : null}
											/>
											<Spacer height={1.5} />
											<Flexed width={'100%'}>
												<MultiSelect
													placeholder={'Choose timezone'}
													// padding={'0'}
													label={'TIMEZONE'}
													// radius={'5px'}
													value={selectedTimezone ? selectedTimezone : _userDetails?.timezone ? {label: _userDetails?.timezone, value: _userDetails?.timezone} : {label: userTimeZone, value: userTimeZone}}
													options={moment.tz.names().map((timezone: any) => {
														return {label: timezone, value: timezone}
													})}
													onChange={(e: any) => {
														setFieldValue('timezone', e?.value, true)
														setSelectedTimezone(e)
													}}
													noOptionsMessage={'no option available'}
												/>
											</Flexed>
											<Spacer height={1.5} />

											<Field
												component={InputField}
												name="linkedin_url"
												type="text"
												label={t('LINKEDIN URL')}
												placeholder={t('Enter your linkedin url')}
												value={values.linkedin_url ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('linkedin_url', event?.value, true)
												}}
												error={touched.linkedin_url && errors.linkedin_url ? {message: errors.linkedin_url ?? ''} : null}
											/>
											<Spacer height={1.5} />
											{!_userDetails?.linkedin_profile_verified ? (
												<>
													<Flexed direction={'row'} align={'start'} justify={'start'}>
														<Button
															small
															borderRadius={'1rem'}
															type="button"
															fontSize={'0.8rem'}
															padding={'8px 16px 8px 16px'}
															label={<>{t('Verify Linkedin Account')}</>}
															background={'#0077B5'}
															color={palette.white}
															lineHeight={'0.89rem'}
															ifClicked={() => (window.location.href = `${AppConstants.server_url}/auth/linkedin?userToken=${_userDetails?.login_token}`)}
														/>
													</Flexed>
													<Spacer height={1.5} />
												</>
											) : (
												''
											)}

											<Field
												component={InputField}
												name="website_url"
												type="text"
												label={t('WEBSITE URL')}
												placeholder={t('Enter your website URL')}
												value={values.website_url ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('website_url', event?.value, true)
												}}
												error={touched.website_url && errors.website_url ? {message: errors.website_url ?? ''} : null}
											/>
											<Spacer height={1.5} />
											<Field
												component={InputField}
												name="user_name"
												type="text"
												label={t('social handle')}
												placeholder={t('Enter your social handle')}
												value={values.user_name ?? undefined}
												isSignup
												disabled
												isContact
												onChange={(event: any) => {
													setFieldValue('user_name', event?.value, true)
												}}
												error={touched.user_name && errors.user_name ? {message: errors.user_name ?? ''} : null}
											/>
											<Spacer height={1.5} />
											<Field
												component={MultiSelect}
												name="gender"
												type="text"
												label={t('GENDER')}
												options={genderOptions}
												isSearchable={false}
												placeholder={t('Select gender')}
												value={values.gender ?? ''}
												onChange={(selectedOption: any) => {
													setFieldValue('gender', selectedOption)
												}}
												error={touched.gender && errors.gender ? {message: errors.gender ?? ''} : null}
											/>

											<Spacer height={1.5} />
											<Field
												component={InputField}
												name="specifications"
												type="textarea"
												label={t('Your Bio')}
												placeholder={t('Write your bio here...')}
												value={values.specifications ?? undefined}
												isSignup
												isContact
												onChange={(event: any) => {
													setFieldValue('specifications', event?.value, true)
												}}
												error={touched.specifications && errors.specifications ? {message: errors.specifications ?? ''} : null}
											/>

											<Spacer height="1" />
										</Col>
									</Row>
									<Spacer height="2" />
									<Flexed direction={'row'} align={'center'} justify={'flex-end'} gap={'1'} margin={'0 1rem '}>
										<Button
											small
											hasBorder
											borderRadius={'1rem'}
											type="button"
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={<>Cancel</>}
											background={palette.white}
											color={palette.blue_500}
											ifClicked={() => {
												closeForm && closeForm()
											}}
											lineHeight={'0.89rem'}
										/>
										<Button
											small
											hasBorder
											disabled={isSubmitting}
											type="submit"
											borderRadius={'1rem'}
											fontSize={'0.8rem'}
											padding={'8px 16px 8px 16px'}
											label={<>{t('Save')}</>}
											background={palette.blue_500}
											color={palette.white}
											lineHeight={'0.89rem'}
										/>
									</Flexed>
								</Form>
							)}
						</Formik>
					</Flexed>
					<Spacer height={2} />
				</Card>
				<Spacer height="2" />
			</Col>
			{optVerificationModel && (
				<VerifyPhoneModal
					phone={userPhone}
					onVerified={() => setOtpVerificationModel(false)}
					onClose={() => setOtpVerificationModel(false)}
					header={t('Enter Otp to verify phone')}
					body={t('We sent an OTP to your phone number. Please check and enter it to verify your phone.')}
				/>
			)}
		</Row>
	)
}

const NameFieldsFlex = styled(Flexed)`
	${media.xs`
flex-direction: column;
    `}
	${media.sm`
	flex-direction: column;

    `}
    ${media.md`
	flex-direction: row;

    `}
	${media.lg`
	flex-direction: row;

    `}
`

const ProfileImgWrapper = styled(Flexed)<any>`
	position: relative;
	display: flex;
`

const UploadIconWrapper = styled(Icons)<any>`
	position: absolute;
	bottom: 0;
	cursor: pointer;
	margin-left: 7rem;
`

export default UpdateProfileForm
